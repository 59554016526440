:root {
  --bg-color: #090c52;
  --bg-color2: #f3f5f9;
  --bg-color3: #0f1369;
  --accent: #0a0d56;
  --accent2: #020235;
  --accent3: #3242f5;
  --accent4: #0ae1ff;
  --headline-color: #b9ccf6;
  --white: #fff;
  --black: #1a1b20;
  --bs-gutter-x: 3rem;
  --font-headline1: 3rem;
  --font-headline2: 2rem;
  --font-headline3: 1.75rem;
  --font-headline4: 1.25rem;
  --font-body: 1.125rem;
}
$mobile: 576px;
$tablet: 768px;
$laptop: 992px;
$desktop: 1200px;
$large: 1400px;
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--black);
}

body {
  font-family: "DM Sans", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.4;
}
h1,
h2 {
  font-size: var(--font-headline1);
}
h3 {
  font-size: var(--font-headline2);
}
h4,
h5 {
  font-size: var(--font-headline3);
}

h6 {
  font-size: var(--font-headline4);
}

img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.container {
  max-width: 100%;
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }
}
.mainWrapper {
  header {
    padding: 1rem 0;
    display: none;

    @media (min-width: 992px) {
      padding: 1rem 0;
      display: none;
    }

    position: relative;
    z-index: 2;

    .logo-wrapper {
      a {
        img {
          max-width: 100%;
        }
      }
    }
  }
  .hero-section {
    padding: 0;
    position: relative;
    overflow: hidden;
    text-align: center;
    @media (min-width: 992px) {
      padding: 2rem 0 0 0;
    }

    .container {
      padding: 0;
      position: relative;
      z-index: 2;
      background: linear-gradient(#0f1369, #020233);

      @media (min-width: 992px) {
        max-width: 100%;
        margin: 0 4rem;
        border-radius: 24px;
      }

      .row {
        min-height: 50vh;
        align-items: center;
        justify-content: center;

        .hero-container {
          padding: 0 1rem;
          border-radius: 8px;
          width: 100%;
          flex: 0 0 auto;
          text-align: center;
          @media (min-width: 992px) {
            width: 50%;
            margin: 0 auto;
          }

          .headline-wrapper {
            h1 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 10px;
              color: var(--black);
              letter-spacing: -0.3px;
              text-transform: capitalize;
              background: var(--bg-color3);
              padding: 8px 6px;
              border-radius: 30px;

              @media (min-width: 992px) {
                padding: 8px 0px;
                text-align: center;
                width: 70%;
              }
            }
          }

          .sub-hero-section {
            padding-top: 3rem;
            .hero-section-wrapper {
              .hero-section-wrapper-content {
                text-align: center;
                margin-bottom: 3rem;
                h2 {
                  font-size: 30px;
                  line-height: 1.33;
                  margin-bottom: 1rem;
                  color: var(--white);

                  @media (min-width: 992px) {
                    font-size: 2.5rem;
                    margin-bottom: 1.5rem;
                  }
                }

                p {
                  font-size: 16px;
                  margin-bottom: 1rem;
                  color: var(--headline-color);

                  @media (min-width: 992px) {
                    font-size: 20px;
                    margin-bottom: 1.5rem;
                  }
                }
              }

              .hero-sectiom-image {
                margin-bottom: 1rem;
              }
              .cta {
                @media (min-width: 992px) {
                  display: flex;
                  justify-content: center;
                }
                a {
                  width: 100%;
                  position: relative;
                  z-index: 0;
                  overflow: hidden;
                  border-radius: 15px;
                  padding-top: 13px;
                  padding-bottom: 13px;

                  font-size: 18px;
                  background: var(--accent3);
                  color: var(--white);
                  display: inline-block;
                  font-weight: 700;
                  line-height: 140%; /* 22.4px */
                  letter-spacing: 0.272px;
                  text-transform: uppercase;
                  border-radius: 24px;
                  @media (min-width: 992px) {
                    padding-left: 40px;
                    padding-right: 40px;
                    border-radius: 10px;
                    width: 46%;
                  }
                  &:hover,
                  &:focus {
                    background-color: var(--accent4);

                    color: var(--black);
                  }
                }

                span {
                  display: block;
                  font-weight: 500;
                  font-size: 1rem;
                  color: var(--accent);
                  padding: 15px 33px;
                  background: var(--white);
                  margin-top: -13px;
                  border-radius: 24px;
                  @media (min-width: 992px) {
                    margin-left: -21px;
                    margin-top: 0;
                    width: 48%;
                  }
                }
              }
            }
          }
        }

        .card-container {
          margin-top: 5rem;
          position: relative;

          .mainbg {
            width: 100%;
            position: relative;

            @media (min-width: 992px) {
              display: flex;
              justify-content: right;
            }
            img {
              position: relative;
              display: block;
            }
          }
        }
        .question-item {
          padding: 0 1rem;
          .congrats {
            max-width: 580px;
            width: 100%;
            padding: 1.25rem;

            @media (min-width: 992px) {
              padding: 2rem;
            }

            border-radius: 18px;
            text-align: center;

            h1 {
              font-size: calc(1.375rem + 1.5vw);
              line-height: 1.5;

              @media (min-width: 992px) {
                font-size: 2.5rem;
              }
            }

            h2 {
              color: var(--accent3);
              margin-bottom: 0.5rem;
              font-size: 1.4rem;
              line-height: 120%;
            }

            a {
              display: block;
              margin: 1rem 0;
              background: var(--accent3);
              color: var(--white);
              font-weight: bold;
              text-decoration: none;
              padding: 1rem;
              font-size: 1.5rem;
              border-radius: 8px;
              box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
              transition: all 0.2s ease-in-out;

              &:hover,
              &:focus {
                background: var(--accent4);
                color: var(--black);
              }
            }

            p {
              color: var(--accent);
              span {
                color: var(--accent3);
                font-weight: bold;
              }
            }

            img {
              margin-top: 1rem;
            }
          }
        }
        .question-wrapper {
          flex: auto;
          width: 100%;
          margin: 3rem 1rem;
          padding: 1.5rem;
          border-radius: 10px;

          @media (min-width: 992px) {
            padding: 2.5rem;
            max-width: 600px;
          }

          .headline-wrapper {
            h1 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 10px;
              color: var(--white);
              letter-spacing: -0.3px;
              text-transform: capitalize;
              text-align: center;
            }
          }

          .question-item {
            .question {
              position: relative;
              text-align: center;

              @media (min-width: 992px) {
                padding: 0;
                background-color: transparent;
                box-shadow: none;
                margin: 0;
              }

              border-radius: 18px;
              margin: 1rem auto;
              max-width: 580px;
              width: 100%;

              h2 {
                font-size: 25px;
                font-weight: 700;
                color: var(--white);

                @media (min-width: 1200px) {
                  font-size: 2rem;
                }
              }

              a {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1rem 0;
                background: var(--accent3);
                color: var(--white);
                font-weight: bold;
                text-decoration: none;
                padding: 0 1rem;
                font-size: 18px;
                border-radius: 8px;
                box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
                height: 50px;
              }
            }
          }

          h1 {
            color: #fff;
          }
        }
      }
    }

    .question-item {
      padding: 0 1rem;
      h1 {
        margin: 2rem 0;
        font-size: calc(1.375rem + 1.5vw);
        line-height: 1.5;
        color: var(--white);

        @media (min-width: 992px) {
          font-size: 2.5rem;
        }
      }
      .congrats {
        max-width: 580px;
        width: 100%;
        padding: 2rem;
        background: var(--white);

        border-radius: 18px;
        text-align: center;

        h2 {
          color: var(--accent);
          margin-bottom: 0.5rem;
          font-size: 1.4rem;
          line-height: 120%;
        }

        a {
          display: block;
          margin: 1rem 0;
          background: var(--accent3);
          color: var(--white);
          font-weight: bold;
          text-decoration: none;
          padding: 1rem;
          font-size: 1.5rem;
          border-radius: 8px;
          box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
          transition: all 0.2s ease-in-out;

          &:hover,
          &:focus {
            background: var(--accent4);
            color: var(--black);
          }
        }

        p {
          span {
            color: var(--accent);
            font-weight: bold;
          }
        }

        img {
          margin-top: 1rem;
        }
      }
    }
    .question-wrapper {
      flex: auto;
      width: 100%;
      margin: 0 1rem;
      padding: 1.5rem;
      border-radius: 10px;

      @media (min-width: 992px) {
        margin: 0;
        padding: 2.5rem;
        max-width: 600px;
      }

      .headline-wrapper {
        h1 {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 10px;
          color: var(--accent);
          letter-spacing: -0.3px;
          text-transform: capitalize;
          text-align: center;
        }
      }

      .question-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .question {
          position: relative;
          text-align: center;

          @media (min-width: 992px) {
            padding: 0;
            background-color: transparent;
            box-shadow: none;
            margin: 0;
          }

          border-radius: 18px;
          margin: 1rem auto;
          max-width: 580px;
          width: 100%;

          h2 {
            font-size: 25px;
            font-weight: 700;

            @media (min-width: 1200px) {
              font-size: 2rem;
            }
          }

          a {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1rem 0;
            background: var(--black);
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            padding: 0 1rem;
            font-size: 18px;
            border-radius: 8px;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            height: 50px;
          }
        }
        .option-renderer-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
          margin-top: 2rem;
          .options-container {
            width: 100%;
            input {
              display: none;
            }

            .option-button {
              width: 100%;
              color: #fff;
              outline: none;
              border: none;
              display: flex;
              align-items: center;
              padding: 16px 32px;
              font-size: 18px;
              border-radius: 4px;
              cursor: pointer;
              justify-content: center;
              background: var(--accent3);

              text-align: center;
              user-select: none;
              font-weight: bold;
            }
          }
        }
      }

      h1 {
        color: #fff;
      }
    }
  }
  .card-section {
    position: relative;
    z-index: 5;
    padding: 0;
    backdrop-filter: blur(15px);
    background: var(--bg-color2);
    border-radius: 24px;
    margin: 3rem 1rem;
    @media (min-width: 992px) {
      margin: 3rem 4rem;
      padding: 2rem 0;
    }

    .container {
      .row {
        justify-content: center;

        .custom-container {
          width: 100%;

          .icon-pack {
            margin-top: 1rem;
            @media (min-width: 992px) {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            }
            .headline-back {
              text-align: center;
              flex: 0 0 auto;
              width: 100%;
              @media (min-width: 992px) {
                width: 30%;
              }
              h2 {
                font-size: 1rem;
                font-weight: 600;
                letter-spacing: 2px;
                color: var(--accent);
                @media (min-width: 992px) {
                  font-size: 1.5rem;
                }
              }
            }

            .logo-icon-pack {
              max-width: 38.75rem;
              margin: 1rem auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              gap: 0.5rem;

              @media (min-width: 992px) {
                width: 70%;
                margin: 0;
                flex: 0 0 auto;
                max-width: 100%;
              }

              img {
                flex: 0 0 auto;
                width: 25%;
                margin-bottom: 0.25rem;

                @media (min-width: 992px) {
                  width: 10%;
                }
              }
            }
          }
        }
      }
    }
  }

  .section1 {
    padding: 3rem 0;
    background-color: var(--bg-color2);
    margin: 4rem 1rem;
    border-radius: 24px;
    @media (min-width: 992px) {
      margin: 3rem 4rem;
    }

    .container {
      .row {
        align-items: center;
        justify-content: center;
        .section-1-content {
          width: 100%;
          text-align: center;

          @media (min-width: 992px) {
            margin: 0 0 auto;
            width: 50%;
          }

          h1 {
            margin-bottom: 1rem;
            line-height: 140%;
            font-size: 24px;
            letter-spacing: 0.036px;
            background: -webkit-linear-gradient(#020235, #3242f5);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (min-width: 992px) {
              font-size: 2.5rem;
            }
          }

          p {
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.08px;
            margin-bottom: 2rem;
          }
        }
        .cta-holder {
          max-width: 580px;
          margin: 1rem 0;
          text-align: center;
          @media (min-width: 992px) {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .cta-link {
            border-radius: 10px;

            a {
              position: relative;
              z-index: 0;
              overflow: hidden;
              border-radius: 10px;
              padding-top: 13px;
              padding-bottom: 13px;
              padding-left: 40px;
              padding-right: 40px;
              font-size: 18px;
              font-weight: 500;
              line-height: 1.5rem;
              letter-spacing: -0.3px;
              color: var(--white);
              display: inline-block;
              background: var(--accent3);
              &:hover,
              &:focus {
                background-color: var(--accent4);
                color: var(--black);
              }
            }
          }

          .cta-span {
            background: var(--white);
            border-radius: 10px;

            span {
              display: block;
              font-size: 16px;
              line-height: 28px;
              padding: 14px;
              color: var(--accent);
            }
          }
        }

        .item-benefits {
          margin: 2rem 0;
          width: 100%;

          @media (min-width: 992px) {
            padding: 0 1rem;
            display: flex;
            flex-wrap: wrap;
          }
          ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li {
              flex: 0 0 auto;
              width: 100%;
              margin-bottom: 1rem;

              @media (min-width: 992px) {
                max-width: 23%;
                min-height: 257px;
              }
              .benefit {
                height: 100%;
                border-radius: 10px;
                background-color: var(--white);
                position: relative;
                padding: 28px;
                box-shadow: 10px 13px 40px 0px rgba(0, 0, 0, 0.05);
                text-align: center;

                .benefitImage {
                  flex: 0 0 auto;
                  position: relative;
                  margin-bottom: 1.5rem;
                  display: inline-block;

                  img {
                    max-width: 55px;
                    vertical-align: middle;
                  }
                }
                .benefitHeadline {
                  flex: 0 0 auto;

                  h3 {
                    font-size: 20px;
                    font-weight: 700;
                    display: block;
                    margin-bottom: 0.5rem;
                    color: var(--black);
                    letter-spacing: 0.06px;
                  }
                  p {
                    font-size: 14px;
                    color: var(--black);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .section2 {
    padding: 3rem 0;

    margin: 4rem 1rem;
    border-radius: 24px;
    background: var(--bg-color2);
    @media (min-width: 992px) {
      margin: 3rem 4rem;
    }
    .container {
      .row {
        .section2-headline-container {
          width: 100%;
          text-align: center;
          @media (min-width: 992px) {
            width: 60%;
            margin: 0 auto 2rem auto;
          }
          h1 {
            margin-bottom: 1rem;
            font-size: 24px;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 0.036px;
            background: -webkit-linear-gradient(#020235, #3242f5);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @media (min-width: 992px) {
              font-size: 2.5rem;
            }
          }

          p {
            margin-top: 2rem;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.08px;
          }
        }

        .section2-stat-counter {
          width: 100%;
          padding: 2rem;
          border-radius: 8px;
          @media (min-width: 992px) {
            display: flex;
            gap: 1rem;
            align-items: center;
            padding: 3rem 4.75rem;
            justify-content: space-evenly;
          }

          .statCounter {
            margin-bottom: 3.5rem;
            text-align: center;
            @media (min-width: 992px) {
              margin: 0;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
            @media (min-width: 992px) {
              .statCounter {
                width: 25%;
              }
            }
            .statCounterImage {
              padding: 1rem;
              border-radius: 8px;

              img {
                max-width: 55px;
              }
            }
            .statCounterStats {
              text-align: center;
              h3 {
                color: var(--black);
                font-size: 20px;
              }

              p {
                color: var(--black);
              }
            }
          }
        }
      }
    }
  }
  .testimonial {
    padding: 3rem 0;
    text-align: left;
    @media (min-width: 992px) {
      padding: 4rem 0;
    }

    img {
      max-width: 100%;

      @media (min-width: 992px) {
        max-width: 580px;
      }
    }

    .testimonial-headline {
      flex: 0 0 auto;
      width: 100%;
      text-align: center;

      h1 {
        margin-bottom: 1rem;
        line-height: 120%;
        font-size: 24px;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
        letter-spacing: 0.036px;
        background: -webkit-linear-gradient(#020235, #3242f5);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media (min-width: 1200px) {
          font-size: 2.5rem;
        }
      }
    }

    .testimonial-content-holder {
      flex: 0 0 auto;
      width: 100%;
      text-align: center;

      h3 {
        font-weight: 500;
      }

      p {
        margin-bottom: 1rem;
        color: var(--black);
      }
    }

    .testimonial-list {
      display: flex;
      margin-top: 2rem;
      flex-wrap: wrap;

      .testimonial-item {
        margin-bottom: 1rem;
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 1.5rem;

        @media (min-width: 992px) {
          width: 33%;
          padding: 0 1rem;
        }

        .testimonial-inner {
          padding: 24px;
          text-align: left;
          border-radius: 10px;
          background-color: var(--bg-color2);

          .testimonial-bottom {
            margin-bottom: 16px;
          }

          .testimonial-top {
            text-align: center;
            &:nth-of-type(1) {
              margin-bottom: 1rem;
            }
            .star-rating {
              img {
                width: 130px;
              }
            }

            .userimg {
              max-width: 38px;
              border-radius: 100%;
            }

            h3 {
              font-size: 25px;
              color: var(--accent);
            }
          }
        }
      }
    }
  }

  .section3 {
    padding: 3rem 0;
    align-items: center;
    .container {
      .row {
        display: block;
        align-items: center;
        justify-content: center;
        @media (min-width: 992px) {
          display: flex;
          justify-content: space-between;
        }

        .faq {
          flex: 0 0 auto;
          width: 100%;
          text-align: center;
          padding: 40px 24px;
          border-radius: 24px;

          @media (min-width: 992px) {
            width: 44%;
            text-align: left;
            padding: 139px 75px;
          }

          h2 {
            font-size: 21px;
            font-weight: 800;
            line-height: 34px;
            letter-spacing: 0.003em;
            margin-bottom: 8px;
            // color:linear-gradient(#020235,#3242F5);
            background: -webkit-linear-gradient(#020235, #3242f5);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @media (min-width: 992px) {
              font-size: 2.5rem;
              line-height: 50px;
            }
          }
          p {
            color: var(--accent);
            @media (min-width: 992px) {
              width: 70%;
            }
          }
        }

        .accordion {
          margin-top: 50px;
          cursor: pointer;
          @media (min-width: 992px) {
            width: 50%;
          }
          .accordion-list {
            margin-bottom: 18px;
            background-color: var(--bg-color2);
            padding: 16px 20px;
            box-sizing: border-box;
            border-radius: 10px;

            .accordion-title {
              display: flex;
              justify-content: space-between;
              .question {
                font-size: 1rem;
                font-weight: 600;
                color: var(--accent);
              }
            }
          }

          .accordion-list.active {
            background-color: var(--bg-color2);
            .question {
              color: var(--accent);
              @media (min-width: 992px) {
                font-size: 21px;
              }
            }
          }
          .icon {
            margin-right: 14px;
            padding: 2px 4px;
            position: relative;
            margin-bottom: 5px 5px;
            display: inline-block;

            border-radius: 8px;
            cursor: pointer;
          }

          .answer {
            margin-top: 10px;
            color: var(--accent);
            font-size: 12px;

            @media (min-width: 992px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 4rem 0 6rem 0;
    background-color: var(--bg-color2);
    .top-footer {
      p {
        font-size: 0.875rem;
        color: var(--accent);
      }
    }

    .bottom-footer {
      .footer-navigation {
        flex: 0 0 auto;
        width: 100%;
        margin: 2rem 0;
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        @media (min-width: 992px) {
          justify-content: space-between;
        }

        .footer-copyright {
          flex: 0 0 auto;
          width: 100%;
          p {
            color: var(--accent);
          }
          @media (min-width: 992px) {
            width: auto;
          }
        }

        .footer-navigation-wrapper {
          flex: 0 0 auto;
          width: 100%;

          @media (min-width: 992px) {
            width: auto;
          }

          ul {
            display: flex;
            justify-content: center;
            align-items: center;

            li {
              color: var(--accent);

              &:nth-child(1) {
                &::after {
                  content: "|";
                  margin: 0 1rem;
                  color: var(--accent);
                }
              }

              a {
                text-decoration: none;
                color: var(--accent);
              }
            }
          }
        }
      }
    }
  }

  .policy-wrapper {
    .policy-title {
      padding: 2rem 0;

      h1 {
        font-size: calc(1.375rem + 1.5vw);

        @media (min-width: 1200px) {
          font-size: 2.5rem;
        }
      }

      flex: 0 0 auto;
      width: 100%;
      border-bottom: 1px solid gray;
      margin-bottom: 2rem;
    }

    .policy-paragraph {
      padding-bottom: 4rem;

      p {
        margin-bottom: 0.5rem;
      }

      h2 {
        font-size: calc(1.325rem + 0.9vw);
        margin: 1rem 0;

        @media (min-width: 1200px) {
          font-size: 2rem;
        }
      }

      h3 {
        font-size: calc(1.3rem + 0.6vw);
        margin-bottom: 1rem;

        @media (min-width: 1200px) {
          font-size: 1.75rem;
        }
      }

      h3 {
        font-size: calc(1.275rem + 0.3vw);

        @media (min-width: 1200px) {
          font-size: 1.5rem;
        }
      }

      ul {
        list-style-type: disc;
        margin-bottom: 0.5rem;
      }
    }
  }
}
